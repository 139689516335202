<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-form>
      <b-row>
        <!-- Name Building -->
        <b-col cols="12">
          <b-form-group label-for="name-building">
            <template v-slot:label>
              Tên tòa nhà <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Tên tòa nhà"
              rules="required"
            >
              <b-form-input
                id="name-building"
                v-model="dataBuilding.buildingName"
                name="nameBuilding"
                placeholder="Tên tòa nhà"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label-for="status">
            <template v-slot:label>
              Trạng thái <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Trạng thái"
              rules="required"
            >
              <v-select
                id="status"
                v-model="dataBuilding.status"
                :options="statusOptions"
                :reduce="option => option.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label-for="orderNo">
            <template v-slot:label>
              Thứ tự <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Thứ tự"
              rules="required"
            >
              <b-form-input
                id="order_no"
                v-model="dataBuilding.orderNo"
                type="number"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="float-left">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="handleSaveData"
            >
              Lưu
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BRow, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { STATUSES } from '@/const/status'
import vSelect from 'vue-select'

export default {
  name: 'FormBuilding',
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  props: {
    dataBuilding: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      formErr: {
        nameBuilding: '',
        maToaNha: '',
      },
      required,
    }
  },
  computed: {
    statusOptions() {
      return STATUSES
    },
  },
  methods: {
    handleSaveData(e) {
      const data = this.dataBuilding
      this.$emit('saveDataBuilding', data)
      e.preventDefault()
    },

    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
